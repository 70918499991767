import { Provider } from 'react-redux';

import Environment from 'shared/util/environment';
import { initSentry } from 'shared/vendor/Sentry';
import { getStore, initStore } from 'store';

import { posthogClient } from './shared/vendor/posthog';

import createStore from 'configureStore';
import Mercury from 'mercury-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import Routes from 'routes';

import 'styles/app.scss';
import 'styles/assets/img/favicon.ico';

const run = () => {
  // Create redux global store
  initStore(createStore());

  initSentry();

  const container = document.getElementById('wheniwork-app');
  const root = createRoot(container);

  window.mercury = new Mercury({ environment: Environment.get() });

  root.render(
    <PostHogProvider client={ posthogClient }>
      <Provider store={ getStore() }>
        <Routes />
      </Provider>
    </PostHogProvider>,
  );
};

run();
