import posthog, { CaptureOptions, Properties } from 'posthog-js';

export const posthogClient = posthog.init(CONFIG.POSTHOG_TOKEN, {
  api_host: CONFIG.POSTHOG_HOST,
  session_recording: {
    maskTextSelector: '.sentry-mask, [data-sentry-mask], [data-private]',
  },
});

export const posthogCapture = (
  eventName: string,
  properties: Properties | undefined = {},
  options: CaptureOptions | undefined = {},
) => {
  posthog.capture(eventName, properties, options);
};

export const posthogIdentify = (
  identifier: string,
  userPropertiesToSet: Properties = {},
  userPropertiesToSetOnce: Properties = {},
) => {
  posthog.identify(identifier, userPropertiesToSet, userPropertiesToSetOnce);
};

export const posthogRegister = (superProperties: Properties = {}) => {
  posthog.register(superProperties);
};
