import setAuthAccount from 'data/auth/actions/setAuthAccount';
import User from 'data/user/model';
import { setUserId } from 'shared/auth';
import { exitApp } from 'store/actions/app';

import posthog from 'posthog-js';

export function selectAccount(user, accounts, redirect = null, exit = true, saml = false) {
  return dispatch => {
    // make sure passed user is a user model
    if (!user.accountId) {
      user = new User(user);
    }
    // users could be switching accounts/users from the previously identified person in posthog so we want to be sure to
    // clear before they end up in WIWJS where we'll identify them to posthog again
    posthog.reset();
    if (saml) {
      setUserId(user.id);

      if (exit) {
        dispatch(exitApp(redirect, user));
      }
    } else {
      dispatch(setAuthAccount(user.accountId))
        .finally(() => {
          setUserId(user.id);

          if (exit) {
            dispatch(exitApp(redirect, user));
          }
        });
    }
  };
}
