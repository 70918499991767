import { deleteToken, deleteUserId } from 'shared/auth';

import posthog from 'posthog-js';

export default () => dispatch => {
  deleteToken();
  deleteUserId();
  if (posthog.__loaded) {
    posthog.reset();
  }
  dispatch({ type: 'auth/logout' });
};
