import Environment from 'shared/util/environment';

import * as Sentry from '@sentry/react';

export function initSentry() {
  Sentry.init({
    release: CONFIG.DEPLOY.TAG || CONFIG.DEPLOY.SHA,
    environment: Environment.get(),
    dsn: 'https://a0aa8436e080c90738c9a7cd9174a840@o4506389234712576.ingest.sentry.io/4506547986563072',
  });
}
