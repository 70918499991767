import api from 'shared/api';
import { checkResponseStatus } from 'shared/auth';
import Environment from 'shared/util/environment';
import reducer from 'store/reducers';

import { posthogUpdate } from './shared/middleware/posthogUpdate';

import { type StoreEnhancer, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

export type WiwStore = ReturnType<typeof configureStore>;
export type WiwState = ReturnType<typeof reducer>;
export default function create(initialState = {}) {
  const middleware = [
    checkResponseStatus,
    posthogUpdate,
  ];

  const enhancers: StoreEnhancer[] = [];

  if (!Environment.isDevelopment()) {

    const sentryEnhancer = Sentry.createReduxEnhancer({});
    enhancers.unshift(sentryEnhancer);
  }

  return configureStore({
    preloadedState: initialState,
    reducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: api,
        },
        serializableCheck: false,
      }).concat(middleware);
    },
    enhancers: existingEnhancers => existingEnhancers.concat(enhancers),
  });
}
