import { deleteToken, deleteUserId } from 'shared/auth';
import { LOGOUT } from 'store/actionTypes';

import posthog from 'posthog-js';

export function attemptLogout() {
  return dispatch => {
    deleteToken();
    deleteUserId();
    if (posthog.__loaded) {
      posthog.reset();
    }
    dispatch({
      type: LOGOUT,
    });
  };
}

