import Environment from 'shared/util/environment';
import { identifyMercury } from 'shared/util/tracking/mercury';
import LaunchDarkly from 'shared/vendor/launchDarkly';
import { posthogIdentify } from './posthog';

export default class Identify {
  static identifyThirdParty(login, user, account, dispatch) {
    this.identifyLaunchDarkly(user, account, dispatch);
    this.identifyPosthog(user, account, login);
    identifyMercury(user, account, login);
  }

  static identifyThirdPartyNoUserOrAccount(login, dispatch) {
    this.identifyLaunchDarkly(null, null, dispatch);
    identifyMercury(null, null, login);
  }

  static identifyLaunchDarkly(user, account, dispatch) {
    try {
      LaunchDarkly.init(user, account, dispatch)
        .then(() => {
          // could handle any further inits or identifies that depend on LD evaluation here, we don't have any though
        });
    } catch (e) {
      console.warn('Launch Darkly identification issue: ', e);
    }
  }

  static identifyPosthog(user, account, login) {
    try {
      posthogIdentify(user.id, {
        'Name': user.fullName(),
        'Email': user.email,
        'Account ID': account.id,
        'Person ID': login.id,
        'User role': user.roleName(),
        'Plan ID': account.planId,
        'Environment': Environment.get(),
      });
    } catch (e) {
      console.warn('Posthog identification issue: ', e);
    }
  }
}
