import { DUPLICATE_ADDRESS_DIALOG } from 'search/dialogs/index';
import { trackClick } from 'shared/util/tracking/mercury';
import { posthogCapture } from 'shared/vendor/posthog';
import { Body, Dialog, Footer, Header } from 'wiw/dialogs';
import Button from 'wiw/ui/Button';

type DuplicateAddressDialogProps = {
  closeDialog: () => void,
  count: number,
  place: string,
  handleCreateClick: () => void,
};

const DuplicateAddressDialog = ({ closeDialog, count, place, handleCreateClick }: DuplicateAddressDialogProps) => {
  const handleDialogCreate = ({ target }: { target: HTMLButtonElement }) => {
    trackClick(target.innerText, { annotations: JSON.stringify({ dialog: DUPLICATE_ADDRESS_DIALOG }) });
    posthogCapture('Click location claimed dialog', { 'Action taken on location claimed dialog': 'Create Workplace' });
    closeDialog();
    handleCreateClick();
  };

  const handleClose = () => {
    trackClick('cancel', { annotations: JSON.stringify({ dialog: DUPLICATE_ADDRESS_DIALOG }) });
    posthogCapture('Click location claimed dialog', { 'Action taken on location claimed dialog': 'Cancel' });
    closeDialog();
  };

  return (
    <Dialog width={ 500 }>
      <Header onClose={ handleClose }>Address Used By Other Workplaces</Header>
      <Body>
        <p>
          { place || 'That address' } is currently used by { count } other workplace{ count > 1 && 's' }.
          Would you like to continue using this address?
        </p>
      </Body>
      <Footer>
        <div className="row no-gutters justify-content-end  mr-1">
          <div className="col-auto mr-2">
            <Button block={ false } onClick={ handleClose } color="secondary">Cancel</Button>
          </div>
          <div className="col-auto">
            <Button color="primary" onClick={ handleDialogCreate }>Create workplace</Button>
          </div>
        </div>
      </Footer>
    </Dialog>
  );
};

export default DuplicateAddressDialog;
